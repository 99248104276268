import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  InjectedConnector,
  NoEthereumProviderError,
} from '@web3-react/injected-connector'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { SUPPORTED_CHAIN_IDS } from '~utils/constants'

const injected = new InjectedConnector({
  supportedChainIds: Object.values(SUPPORTED_CHAIN_IDS),
})

const AuthContext = createContext({
  connect: () => {},
  disconnect: () => {},
})

// eslint-disable-next-line import/prefer-default-export,react/prop-types
export const AuthProvider = ({ children }) => {
  const { active, error, activate, deactivate } = useWeb3React()
  const [isMetamaskAuthVerified, setIsMetamaskAuthVerified] = useState(false)

  const connect = async () => {
    await activate(injected, (e) => {
      //
      // Wrong network error
      if (e instanceof UnsupportedChainIdError) {
        //
        // Attempt to switch chain
        if (window.ethereum) {
          window.ethereum
            .request({
              method: 'wallet_switchEthereumChain',
              params: [
                { chainId: `0x${SUPPORTED_CHAIN_IDS.RINKEBY.toString(16)}` },
              ],
            })
            .then(() => connect())
            .catch((switchError) => {
              // User rejected the switch
              if (switchError.code === 4001) {
                // Nothing special, handled automatically
              }

              // Network not added to MetaMask
              if (switchError.code === 4902) {
                // TODO: add "wallet_addEthereumChain" if not default ethereum network
              }
            })
        }
        return
      }

      // No MetaMask error
      if (e instanceof NoEthereumProviderError) {
        // TODO: toast needed
        console.log('MetaMask not installed!')
      }
    })
    setIsMetamaskAuthVerified(true)
  }

  // Reconnect is already authorized by MetaMask
  // E.g. page refresh
  // Does not work with deactivate()
  useEffect(() => {
    if (active || error) {
      setIsMetamaskAuthVerified(true)
      return
    }

    injected
      .isAuthorized()
      .then((isAuthorized) => {
        if (isAuthorized) {
          connect()
        } else {
          setIsMetamaskAuthVerified(true)
        }
      })
      .catch(() => {
        setIsMetamaskAuthVerified(true)
      })
  }, [active, error])

  const disconnect = async () => {
    try {
      await deactivate()
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.log(ex)
    }
  }

  const memoedValue = useMemo(
    () => ({
      connect,
      disconnect,
      isMetamaskAuthVerified,
    }),
    [connect, disconnect, isMetamaskAuthVerified]
  )

  return (
    <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>
  )
}

export default function useAuth() {
  return useContext(AuthContext)
}
