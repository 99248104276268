import React from 'react'
import DATA from './constants'
import * as s from './style.module.scss'

const Total = () => {
  return (
    <div className={s.total}>
      {DATA.map(({ title, label, value }) => (
        <div className={s.total_item} key={title}>
          <p className={s.total_heading}>
            <span className={s.total_title}>{title}</span>
            {typeof label !== 'undefined' && (
              <span className={s.total_label}>{label}</span>
            )}
          </p>
          <p className={s.total_value}>
            {typeof value !== 'undefined' ? value : 'N/A'}
          </p>
        </div>
      ))}
    </div>
  )
}

export default Total
