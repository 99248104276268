import React, { useState, useEffect } from 'react'
import * as s from './style.module.scss'

const ToggleMode = () => {
  let websiteTheme
  if (typeof window !== `undefined`) {
    websiteTheme = window.__theme
  }

  const [theme, setTheme] = useState(websiteTheme)

  useEffect(() => {
    setTheme(window.__theme)
  }, [])

  const ThemeToggle = () => {
    window.__setPreferredTheme(websiteTheme === 'dark' ? 'light' : 'dark')
    setTheme(websiteTheme === 'dark' ? 'light' : 'dark')
  }

  return (
    <button type="button" className={s.toggler} onClick={ThemeToggle}>
      <span className={s.toggler_slider} />
      <span className={s.toggler_title}>
        {theme === 'dark' ? 'Dark' : 'Light'}
      </span>
    </button>
  )
}

export default ToggleMode
