import React from 'react'
import { Container } from 'react-bootstrap'

import Total from '~components/Total'
import Account from '~components/Account'

import * as s from './style.module.scss'

const Header = () => (
  <Container as="header" className={s.header}>
    <Total />
    <Account />
  </Container>
)

export default Header
