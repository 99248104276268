/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from '@reach/router'

import Header from '../Header'
// import Footer from '../Footer'

import { layout } from './style.module.scss'
import ToastContainerCustom from '~components/ToastContainer'

import OverlayContext from '~contexts/OverlayContext'

import '~styles/app.scss'

const Layout = ({ children }) => {
  const location = useLocation()
  const { addToastToStack } = useContext(OverlayContext)

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const errType = searchParams.get('err')

    if (errType) {
      addToastToStack({ type: errType })
    }
  }, [location.search])

  return (
    <div className={layout}>
      <ToastContainerCustom />
      <Header />
      <main className="main">{children}</main>
      {/* <Footer siteTitle={data.site.siteMetadata?.title} /> */}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
