const DATA = [
  { title: 'Tweets Total', value: '385' },
  {
    title: 'Tokens Total',
    label: 'x1.2',
    value: '4,854',
  },
]

export default DATA
