import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

const TwitterAuthContext = createContext({
  disconnect: () => {},
})

// eslint-disable-next-line import/prefer-default-export,react/prop-types
export const TwitterAuthProvider = ({ children }) => {
  const [isTwitterAuthVerified, setIsTwitterAuthVerified] = useState(false)

  useEffect(() => {
    setIsTwitterAuthVerified(!!localStorage.getItem('user_jwt'))
  }, [])

  const disconnect = async () => {
    try {
      localStorage.removeItem('user_jwt')
      localStorage.removeItem('user_name')
      localStorage.removeItem('user_profile_img')
      setIsTwitterAuthVerified(false)
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.log(ex)
    }
  }

  const memoedValue = useMemo(
    () => ({
      disconnect,
      isTwitterAuthVerified,
    }),
    [disconnect, isTwitterAuthVerified]
  )

  return (
    <TwitterAuthContext.Provider value={memoedValue}>
      {children}
    </TwitterAuthContext.Provider>
  )
}

export default function useTwitterAuth() {
  return useContext(TwitterAuthContext)
}
