import React, { useEffect, useState } from 'react'
import { Button, Dropdown } from 'react-bootstrap'
import { useWeb3React } from '@web3-react/core'

import Icon from '~components/Icon'
import ToggleMode from '~components/ToggleMode'
import useWindowSize from '~hooks/useWindowSize'
import * as s from './style.module.scss'
import useAuth from '~hooks/useAuth'
import useTwitterAuth from '~hooks/useTwitterAuth'
import { API_ROOT_URL } from '~constants/api-constants'

const Account = () => {
  const { isTwitterAuthVerified: isLogin, disconnect: twitterDisconnect } =
    useTwitterAuth()
  const [username, setUsername] = useState('')
  const [userProfileImg, setUserProfileImg] = useState('')

  const [width] = useWindowSize()
  const isLessBigTablet = width < 920
  const isLessSmallTablet = width < 701
  const isMobile = width < 640

  const { connect, isMetamaskAuthVerified } = useAuth()
  const { active: isConnected } = useWeb3React()

  useEffect(() => {
    if (isLogin) {
      setUsername(localStorage.getItem('user_name'))
      setUserProfileImg(localStorage.getItem('user_profile_img'))
    }
  }, [isLogin])

  return (
    <div className={s.account}>
      {isLogin ? (
        <>
          {isMetamaskAuthVerified && !isConnected && !isLessSmallTablet && (
            <Button variant="header btn-metamask" onClick={connect}>
              <Icon name="fox" size={24} />
              {!isLessBigTablet && <span>Connect Metamask</span>}
            </Button>
          )}
          <Dropdown align="end">
            <Dropdown.Toggle variant="user">
              {!isMobile && <p>{username}</p>}
              <div className={s.account_avatar}>
                <img
                  src={userProfileImg || 'https://picsum.photos/id/100/40/40'}
                  alt="Rose Mario"
                />
              </div>
              {!isMobile && <Icon name="dots" size={20} />}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <div className="dropdown-inner">
                <div className="dropdown-content">
                  {/* {isConnected && ( */}
                  {/*  <Button variant="header btn-dropdown" onClick={disconnect}> */}
                  {/*    <Icon name="fox" size={24} /> */}
                  {/*    <span>Disconnect Metamask</span> */}
                  {/*  </Button> */}
                  {/* )} */}
                  {!isConnected && isLessSmallTablet && (
                    <Button variant="header btn-dropdown" onClick={connect}>
                      <Icon name="fox" size={24} />
                      <span>Connect Metamask</span>
                    </Button>
                  )}
                  <Button
                    variant="primary btn-dropdown"
                    onClick={() => twitterDisconnect()}
                  >
                    <span>Log Out</span> <Icon name="arrow-right" size={16} />
                  </Button>
                </div>
                {isMobile && isLogin && <ToggleMode />}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </>
      ) : (
        <Button
          variant="header btn-login"
          onClick={() => {
            window.location.href = `${API_ROOT_URL}/connect/twitter`
          }}
        >
          <span className="btn-login_icon">
            <Icon name="twitter" size={[19, 16]} />
          </span>
          {!isMobile && <span>Log In via Twitter</span>}
        </Button>
      )}
    </div>
  )
}

export default Account
